import CircleVisualObject from "../visual/CircleVisualObject";
import VisualObject from "../visual/VisualObject";
import PhysicalObject from "./PhysicalObject";

export default class GravityObject extends PhysicalObject {

    // Visual representaion
    visualization: VisualObject

    public constructor(m: number, d: number, x: number, y: number, vx: number, vy: number) {
        super(m, d, x, y, vx, vy);

        this.visualization = CircleVisualObject.of(this);
    }

    public updateAcceleration(peers: Array<PhysicalObject>) {
        // TODO
        if (!this.dead) {
            const frameTime = 0.000001;

            this.ax = 0.0;
            this.ay = 0.0;
            peers.forEach(o => {
                if (o === this) return;
                const dx = (o.x - this.x);
                const dy = (o.y - this.y);
                const d = Math.sqrt(dx * dx + dy * dy);
                const d2 = Math.pow(d, 2);
                const adx = o.m * dx / d2 * frameTime; // / this.m
                const ady = o.m * dy / d2 * frameTime; // / this.m
                // console.log(dx, dy, d, d2, adx, ady);
                this.ax += adx;
                this.ay += ady;
            })
        }

        super.updateAcceleration(peers);
    }

    public explode() {
        if (!this.dead) {
            // TODO
        }
        super.explode();
    }

    public getVisualization() : VisualObject | null {
        return this.visualization;
    }
}
