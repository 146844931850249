import { useEffect, useRef, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastForwardOutlinedIcon from "@mui/icons-material/FastForwardOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import MenuIcon from "@mui/icons-material/Menu";

import GravityObject from "./simulator/GravityObject";
import PhysicalObject from "./simulator/PhysicalObject";
import CircleVisualObject from "./visual/CircleVisualObject";

function App() {
  const rootRef = useRef<HTMLDivElement>(null);
  // const containerRef = useRef<HTMLDivElement>(null);

  const canvasInteractiveRef = useRef<HTMLCanvasElement>(null);
  const canvasOverviewRef = useRef<HTMLCanvasElement>(null);
  const canvasPreviewRef = useRef<HTMLCanvasElement>(null);

  const animationCancelRef = useRef<number>(0);
  const framesLeft = useRef<number>(0);
  const frameLastTimestamp = useRef<DOMHighResTimeStamp>(0);

  const objectsExample = () => [
    new GravityObject(1, 0.01, 0.1, 0.1, 0.0001, 0),
    new GravityObject(1, 0.01, 0.9, 0.1, 0, 0.0001),
    new GravityObject(1, 0.01, 0.9, 0.9, -0.0001, 0),
    new GravityObject(1, 0.01, 0.1, 0.9, 0, -0.0001),
  ];

  const objectsRef = useRef<Array<PhysicalObject> | null>(objectsExample());

  const [menuVisible, setMenuVisible] = useState(true);

  const [clickStart, setClickStart] = useState<{ x: number; y: number } | null>(
    null
  );
  const [clickEnd, setClickEnd] = useState<{ x: number; y: number } | null>(
    null
  );

  const [stageWidth, setStageWidth] = useState(0);
  const [stageHeight, setStageHeight] = useState(0);

  const [inProgress, setInProgress] = useState(false);
  const [running, setRunning] = useState(false);

  const [showOverview, setShowOverview] = useState(true);
  const [turboMode, setTurboMode] = useState(false);
  const [endless, setEndless] = useState(true);

  const [frames, setFrames] = useState(15100);

  const canvasSize = Math.min(stageWidth, stageHeight);

  const updateCanvasTransform = (canvasElement: HTMLCanvasElement) => {
    const canvasContext = canvasElement.getContext("2d");
    if (canvasContext === null) throw "canvasContext - null";

    canvasContext.clearRect(0, 0, stageWidth, stageHeight);
    canvasContext.resetTransform();
    canvasContext.scale(canvasSize, canvasSize);
    canvasContext.lineWidth = 1 / canvasSize;
  };

  const drawOverview = () => {
    if (canvasOverviewRef.current === null) throw "canvasOverviewRef - null";
    const canvasOverviewContext = canvasOverviewRef.current.getContext("2d");
    if (canvasOverviewContext === null) throw "canvasOverviewContext - null";

    canvasOverviewContext.clearRect(0, 0, stageWidth, stageHeight);
    // canvasOverviewContext.strokeStyle = 'green';
    // canvasOverviewContext.strokeRect(0, 0, 1, 1);

    if (!showOverview) return;
    objectsRef.current?.forEach((o) =>
      o.getVisualization()?.drawOverview(canvasOverviewContext)
    );
  };

  const drawPreview = () => {
    if (canvasPreviewRef.current === null) throw "canvasPreviewRef - null";
    const canvasPreviewContext = canvasPreviewRef.current.getContext("2d");
    if (canvasPreviewContext === null) throw "canvasPreviewContext - null";

    objectsRef.current?.forEach((o) =>
      o.getVisualization()?.drawOutput(canvasPreviewContext)
    );
  };

  const redrawCanvas = () => {
    setClickStart(null);
    if (inProgress) {
      stopAnimation();
      // TODO: restore state before start was selected (instead of reloading example)
      objectsRef.current = objectsExample();
    }

    if (canvasInteractiveRef.current === null)
      throw "canvasInteractiveRef - null";
    if (canvasOverviewRef.current === null) throw "canvasOverviewRef - null";
    if (canvasPreviewRef.current === null) throw "canvasPreviewRef - null";

    updateCanvasTransform(canvasInteractiveRef.current);
    updateCanvasTransform(canvasOverviewRef.current);
    updateCanvasTransform(canvasPreviewRef.current);

    drawOverview();
    drawPreview();
  };

  // Resize detector
  useEffect(() => {
    if (rootRef.current === null) throw "rootRef - null";
    const resizeObserver = new ResizeObserver(() => {
      if (rootRef.current === null) throw "rootRef - null";
      setStageWidth(rootRef.current.clientWidth);
      setStageHeight(rootRef.current.clientHeight);
    });

    resizeObserver.observe(rootRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // Redraw when resized
  useEffect(redrawCanvas, [stageWidth, stageHeight]);

  // Redraw overview when it's visibility is changed
  useEffect(drawOverview, [showOverview]);

  const interactiveCreatingParams = (
    clickStart: { x: number; y: number } | null,
    clickEnd: { x: number; y: number } | null
  ) => {
    if (clickStart === null) return null;
    if (clickEnd === null) return null;

    const sx = clickStart.x / canvasSize;
    const sy = clickStart.y / canvasSize;
    const ex = clickEnd.x / canvasSize;
    const ey = clickEnd.y / canvasSize;

    return {
      sx,
      sy,
      ex,
      ey,
      dx: ex - sx,
      dy: ey - sy,
    };
  };

  const redrawInteractiveCreating = (context: CanvasRenderingContext2D) => {
    const params = interactiveCreatingParams(clickStart, clickEnd);
    if (params === null) return;

    new CircleVisualObject(() => ({
      x: params.sx,
      y: params.sy,
      r: Math.sqrt(params.dx * params.dx + params.dy * params.dy),
      styleOverview: "#2962ff",
    })).drawOverview(context);
  };

  const redrawInteractiveLayer = () => {
    if (canvasInteractiveRef.current === null)
      throw "canvasInteractiveRef - null";
    const context = canvasInteractiveRef.current.getContext("2d");
    if (context === null) throw "context - null";

    context.clearRect(0, 0, stageWidth, stageHeight);

    redrawInteractiveCreating(context);
  };

  // Redraw when resized or interactive parameters changed
  useEffect(redrawInteractiveLayer, [clickStart, clickEnd]);

  const createObject = (
    clickStart: { x: number; y: number } | null,
    clickEnd: { x: number; y: number } | null
  ) => {
    const params = interactiveCreatingParams(clickStart, clickEnd);
    if (params === null) return;

    objectsRef.current?.push(
      new GravityObject(
        (params.dx * params.dx + params.dy * params.dy) * 100,
        0.1,
        params.sx,
        params.sy,
        0,
        0
      )
    );
    redrawCanvas();
  };

  const elementOffset = (element: HTMLElement): { x: number; y: number } => {
    const offsetParent = element.offsetParent
      ? elementOffset(element.offsetParent as HTMLElement)
      : { x: 0, y: 0 };
    return {
      x: offsetParent.x + element.offsetLeft,
      y: offsetParent.y + element.offsetTop,
    };
  };

  const step = () => {
    const objs = objectsRef.current;
    if (objs === null) return;

    objs.forEach((o) => o.updateAcceleration(objs));
    objs.forEach((o) => o.updateSpeed(objs));
    objs.forEach((o) => o.updatePosition(objs));
    drawPreview();
  };

  const animate = (timestamp: DOMHighResTimeStamp) => {
    if (framesLeft.current !== 0) {
      animationCancelRef.current = requestAnimationFrame(animate);
    }

    const stepLength = (1 / 120) * 1000;

    while (true) {
      const elapsed = timestamp - frameLastTimestamp.current;
      if (!turboMode && elapsed < stepLength) break;

      if (elapsed > 3 * stepLength) {
        frameLastTimestamp.current = timestamp;
      } else {
        frameLastTimestamp.current += stepLength;
      }

      for (let i = 0; i < (turboMode ? 1000 : 1); i++) {
        if (framesLeft.current == 0) {
          stopAnimation();
          break;
        }
        framesLeft.current--;
        step();
      }
      drawOverview();

      if (turboMode) break;
    }
  };

  const stopAnimation = () => {
    if (!running) return;
    cancelAnimationFrame(animationCancelRef.current);
    setRunning(false);
  };

  const startAnimation = () => {
    if (running) return;

    framesLeft.current = endless ? -1 : frames;
    animationCancelRef.current = requestAnimationFrame(animate);

    setRunning(true);
    setInProgress(true);
  };

  const updateAnimation = () => {
    if (!running) return;
    cancelAnimationFrame(animationCancelRef.current);
    animationCancelRef.current = requestAnimationFrame(animate);
  };

  // Apply state changes to animation
  useEffect(updateAnimation, [showOverview, turboMode, running]);

  return (
    <div
      ref={rootRef}
      style={{
        position: "relative",
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        background: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        // ref={containerRef}
        style={{
          position: "relative",
          width: canvasSize,
          height: canvasSize,
          overflow: "hidden",
          border: "1px solid #121212",
        }}
      >
        <canvas
          ref={canvasPreviewRef}
          width={canvasSize}
          height={canvasSize}
          style={{
            position: "absolute",
            background: "black",
          }}
        />
        <canvas
          ref={canvasOverviewRef}
          width={canvasSize}
          height={canvasSize}
          style={{
            position: "absolute",
            background: "transparent",
          }}
        />
        <canvas
          ref={canvasInteractiveRef}
          width={canvasSize}
          height={canvasSize}
          style={{
            position: "absolute",
            background: "transparent",
            touchAction: "none",
          }}
          onPointerDown={(e) => {
            if (inProgress) return;
            const offset = elementOffset(e.target as HTMLElement);
            const clickStartCurrent = {
              x: e.pageX - offset.x,
              y: e.pageY - offset.y,
            };
            setClickStart(clickStartCurrent);
            setClickEnd(null);
            // console.log("Start: ", clickStartCurrent);
          }}
          onPointerMove={(e) => {
            if (inProgress) return;
            if (clickStart === null) return;
            const offset = elementOffset(e.target as HTMLElement);
            const clickEndCurrent = {
              x: e.pageX - offset.x,
              y: e.pageY - offset.y,
            };
            setClickEnd(clickEndCurrent);
            // console.log("Move: ", clickStart, " -> ", clickEndCurrent);
          }}
          onPointerUp={(e) => {
            if (inProgress) return;
            const offset = elementOffset(e.target as HTMLElement);
            const clickEndCurrent = {
              x: e.pageX - offset.x,
              y: e.pageY - offset.y,
            };

            createObject(clickStart, clickEndCurrent);

            setClickStart(null);
            setClickEnd(null);
            // console.log("End: ", clickStart, " -> ", clickEndCurrent);
          }}
          onPointerCancel={(e) => {
            setClickStart(null);
            setClickEnd(null);
          }}
        />
      </div>
      {!menuVisible ? (
        <Button
          variant="contained"
          startIcon={<MenuIcon />}
          color="primary"
          style={{ position: "absolute", top: 20, left: 20 }}
          onClick={() => {
            setMenuVisible(true);
          }}
        >
          Menu
        </Button>
      ) : (
        <Paper
          style={{
            overflowY: "auto",
            position: "absolute",
            top: 10,
            left: 10,
            width: 300,
            padding: 20,
            height: window.innerHeight - 60, // 60 is margin
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 20,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h4">PhyContext</Typography>
              <IconButton
                onClick={() => {
                  setMenuVisible(false);
                }}
              >
                <ClearIcon />
              </IconButton>
            </div>
            <Alert severity="error">
              Website is work in progress, expect more features in future.
            </Alert>

            <div style={{ display: "flex", justifyContent: "center", gap: 10 }}>
              <Button
                startIcon={<PlayArrowIcon />}
                style={{ width: 100 }}
                variant="contained"
                color="success"
                disabled={running}
                onClick={(e) => startAnimation()}
              >
                Start
              </Button>
              <Button
                startIcon={<StopIcon />}
                style={{ width: 100 }}
                variant="contained"
                color="error"
                disabled={!running}
                onClick={(e) => stopAnimation()}
              >
                Stop
              </Button>
            </div>
            <Button
              startIcon={<SkipNextIcon />}
              disabled={running}
              variant="outlined"
              onClick={(e) => {
                if (!inProgress) setInProgress(true);
                step();
                drawOverview();
              }}
            >
              Step
            </Button>
            <Button
              startIcon={<ClearIcon />}
              variant="outlined"
              color="error"
              onClick={(e) => {
                stopAnimation();

                objectsRef.current = [];
                redrawCanvas();

                setInProgress(false);
              }}
            >
              Clear
            </Button>
            <Button
              startIcon={<DownloadIcon />}
              variant="contained"
              onClick={(e) => {
                if (!canvasPreviewRef.current) return;

                const link = document.createElement("a");
                link.setAttribute("download", "PhyOutput.png");
                link.setAttribute(
                  "href",
                  canvasPreviewRef.current
                    .toDataURL("image/png")
                    .replace("image/png", "image/octet-stream")
                );
                link.click();
                link.remove();
              }}
            >
              Download
            </Button>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<VisibilityOffOutlinedIcon />}
                    checkedIcon={<VisibilityIcon />}
                    checked={showOverview}
                    onChange={(e) => {
                      setShowOverview(e.target.checked);
                    }}
                  />
                }
                label="Objects visibility"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<FastForwardOutlinedIcon />}
                    checkedIcon={<FastForwardIcon />}
                    checked={turboMode}
                    onChange={(e) => {
                      setTurboMode(e.target.checked);
                    }}
                  />
                }
                label="Turbo mode"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<AllInclusiveIcon />}
                    checkedIcon={<AllInclusiveIcon />}
                    checked={endless}
                    onChange={(e) => {
                      setEndless(e.target.checked);
                    }}
                  />
                }
                label="Endless"
              />
            </FormGroup>

            <TextField
              disabled={endless}
              type="number"
              label="Frames until stop"
              inputProps={{ min: 0 }}
              value={frames}
              onChange={(e) => {
                setFrames(parseInt(e.target.value));
              }}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <Alert severity="warning">
              Resizing window will reset whole scene and reload example objects.
              In future it will reload state before pressing start button.
            </Alert>
            <Alert severity="warning">Clear will remove every object.</Alert>
            <Alert severity="info">
              <AlertTitle>Usage</AlertTitle>
              <ol style={{ paddingLeft: 15 }}>
                <li>
                  Place objects (only mass/size can be set for new objects right
                  now)
                </li>
                <li>Press start button and wait (or use turbo mode)</li>
                <li>Press download button to get result</li>
              </ol>
            </Alert>
          </div>
        </Paper>
      )}
    </div>
  );
}

export default App;
