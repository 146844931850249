import VisualObject from "../visual/VisualObject";

export default class PhysicalObject {
    dead: boolean;

    children: Array<PhysicalObject>;

    // Weight
    m: number;
    // Density
    d: number;

    // Position
    x: number;
    y: number;

    // Speed
    vx: number;
    vy: number;

    // Acceleration
    ax: number;
    ay: number;

    public constructor(m: number, d: number, x: number, y: number, vx: number, vy: number) {
        this.dead = false;

        this.children = [];

        this.m = m;
        this.d = d;

        this.x = x;
        this.y = y;

        this.vx = vx;
        this.vy = vy;

        this.ax = 0.0;
        this.ay = 0.0;
    }

    public updateAcceleration(peers: Array<PhysicalObject>) {
        this.children.forEach(child => child.updateAcceleration(this.children));
    }

    public updateSpeed(peers: Array<PhysicalObject>) {
        if (!this.dead) {
            this.vx += this.ax;
            this.vy += this.ay;
        }

        this.children.forEach(child => child.updateSpeed(this.children));
    }

    public updatePosition(peers: Array<PhysicalObject>) {
        if (!this.dead) {
            this.x += this.vx;
            this.y += this.vy;
        }

        this.children.forEach(child => child.updateSpeed(this.children));
    }

    public explode() {
        this.die();
    }

    public die() {
        this.dead = true;
    }

    public getVisualization() : VisualObject | null {
        return null;
    }
}
