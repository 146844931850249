import PhysicalObject from "../simulator/PhysicalObject";
import VisualObject from "./VisualObject";

interface CircleParametersFunction {
    (): {
        x: number,
        y: number,
        r: number,
        styleOverview?: string,
        styleOutput?: string
    };
}

export default class CircleVisualObject extends VisualObject {

    parametersFnc: CircleParametersFunction

    public constructor(parametersFnc: CircleParametersFunction) {
        super();

        this.parametersFnc = parametersFnc;
    }

    public static of(o: PhysicalObject): CircleVisualObject {
        return new CircleVisualObject(() => {
            return {
                x: o.x,
                y: o.y,
                r: Math.sqrt(o.m) * o.d,
                styleOverview: 'white',
                styleOutput: 'rgba(50,50,150,0.1)', // 0.01
            }
        })
    }

    public drawOverview(context: CanvasRenderingContext2D) {
        let params = this.parametersFnc();
        if (params.styleOverview == undefined) return;

        context.beginPath();
        context.arc(params.x, params.y, params.r, 0, 2 * Math.PI, false);
        context.strokeStyle = params.styleOverview;
        context.stroke();
    }

    public drawOutput(context: CanvasRenderingContext2D) {
        let params = this.parametersFnc();
        if (params.styleOutput == undefined) return;

        context.beginPath();
        context.arc(params.x, params.y, params.r, 0, 2 * Math.PI, false);
        context.strokeStyle = params.styleOutput;
        context.stroke();
    }
}
