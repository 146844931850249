import { createRoot } from "react-dom/client";
import "./index.css";

import App from "./App";
import { createTheme, ThemeProvider } from "@mui/material";
import blue from "@mui/material/colors/blue";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: blue,
  },
});

const container = document.getElementById("app");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <ThemeProvider theme={darkTheme}>
    <App />
  </ThemeProvider>
);
